<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_16334_137001)">
      <path
        d="M15 19L15 1L1 1L1 19H15Z"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path d="M12 9H4" stroke-width="1.5" stroke-miterlimit="10" />
      <path d="M8 5H4" stroke-width="1.5" stroke-miterlimit="10" />
      <path
        d="M6 16L8 14L10 15L12 13"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_16334_137001">
        <rect width="16" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
